import React from 'react';
import Layout from '../components/Layout';
import { Grid, Cell } from '../theme';
import { colors } from '../theme/constants';
import { Container } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import IntroPages from '../components/common/IntroPages';
import { SectionWrapper } from '../components/common/SectionWrapper';
import ContentWrapper from '../components/common/ContentWrapper';
import FormContact from '../components/common/FormContact';
import { device } from '../theme';

const FormContainerBox = styled.div`
  padding: 2rem;
  border-radius: 0.5rem;
  /* background: rgba(3, 37, 53, 1); */
  background: ${colors.gray.formBgGrey};
  color: ${colors.white};
  border: 0.1rem solid #ebebf1;
  @media ${device.laptop} {
    padding: 4rem 2.8rem;
  }
`;

const WidgetContainer = styled.div`
  border: none;
  transition: 0.3s;
  margin-bottom: 5rem;
  box-shadow: 0.5rem 1rem 1.8rem ${colors.gray.mainGrey};

  @media ${device.laptopM} {
    box-shadow: none;
    :hover {
      box-shadow: 0.5rem 1rem 1.8rem ${colors.gray.mainGrey};
    }
  }
`;

const ContactInfoBox = styled.div`
  padding: 3rem 3rem 2.5rem;
  font-size: 1.6rem;
  display: flex;
`;

const ContactBoxIcon = styled.span`
  color: ${colors.blue.navBlue};
  svg,
  img {
    width: 3rem;
    height: 3rem;
  }
`;

const ContactInfoText = styled.div`
  padding-left: 5rem;
  font-weight: 500;
`;

const ContactInfoHeder = styled.h6`
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
  color: ${colors.blue.mainBlue};
`;

const ContactInfoContent = styled.p`
  margin-bottom: 0;
  color: ${colors.blue.textBlue};
`;

const FormTitle = styled.h2`
  font-size: 2.6rem;
  font-weight: 800;
  line-height: 1.33;
  text-transform: none;
  color: ${colors.yellow.mainDarkYellow};
  /* margin-bottom: 1rem; */
  @media ${device.tablet} {
    font-size: 2rem;
  }
  @media ${device.laptop} {
    font-size: 3rem;
  }
  @media ${device.laptopM} {
    font-size: 3.6rem;
  }
`;

const FormSubtitle = styled.p`
  font-size: 1.4rem;
  font-weight: bold;
  color: ${colors.blue.mainBlue};
  margin-bottom: 2rem;
`;
const ContactPage = (props) => {
  return (
    <Layout>
      <IntroPages
        title={'Contact Us'}
        menuItemOne={'home'}
        menuItemTwo={'contact us'}
      ></IntroPages>
      <SectionWrapper>
        <Container>
          {/* <Grid columnGap={'2rem'} rows={2} columns={1}> */}
          {/* <Cell> */}
          <ContentWrapper
            fontColor={colors.yellow.mainDarkYellow}
            colorFont={colors.blue.mainBlue}
            colorBlue={colors.blue.textBlue}
            pageLabel={'// CONTACT DETAILS'}
            title={'Contact us'}
            contentOne={
              'Let us know how we can help. Fill out the form and we’ll be in touch as soon as possible. We will be happy to answer your questions.'
            }
          />
          {/* <WidgetContainer>
                <ContactInfoBox>
                  <ContactBoxIcon>
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="globe"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 496 512"
                    >
                      <path
                        fill="currentColor"
                        d="M336.5 160C322 70.7 287.8 8 248 8s-74 62.7-88.5 152h177zM152 256c0 22.2 1.2 43.5 3.3 64h185.3c2.1-20.5 3.3-41.8 3.3-64s-1.2-43.5-3.3-64H155.3c-2.1 20.5-3.3 41.8-3.3 64zm324.7-96c-28.6-67.9-86.5-120.4-158-141.6 24.4 33.8 41.2 84.7 50 141.6h108zM177.2 18.4C105.8 39.6 47.8 92.1 19.3 160h108c8.7-56.9 25.5-107.8 49.9-141.6zM487.4 192H372.7c2.1 21 3.3 42.5 3.3 64s-1.2 43-3.3 64h114.6c5.5-20.5 8.6-41.8 8.6-64s-3.1-43.5-8.5-64zM120 256c0-21.5 1.2-43 3.3-64H8.6C3.2 212.5 0 233.8 0 256s3.2 43.5 8.6 64h114.6c-2-21-3.2-42.5-3.2-64zm39.5 96c14.5 89.3 48.7 152 88.5 152s74-62.7 88.5-152h-177zm159.3 141.6c71.4-21.2 129.4-73.7 158-141.6h-108c-8.8 56.9-25.6 107.8-50 141.6zM19.3 352c28.6 67.9 86.5 120.4 158 141.6-24.4-33.8-41.2-84.7-50-141.6h-108z"
                      ></path>
                    </svg>
                  </ContactBoxIcon>
                  <ContactInfoText>
                    <ContactInfoHeder>Our Address:</ContactInfoHeder>
                    <ContactInfoContent>
                      Softiware, Oslo, Norway
                    </ContactInfoContent>
                  </ContactInfoText>
                </ContactInfoBox>
              </WidgetContainer> */}
          {/* <WidgetContainer>
                <ContactInfoBox>
                  <ContactBoxIcon>
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="envelope"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
                      ></path>
                    </svg>
                  </ContactBoxIcon>
                  <ContactInfoText>
                    <ContactInfoHeder>Our Mailbox:</ContactInfoHeder>
                    <ContactInfoContent>
                      support@saftiware.com
                    </ContactInfoContent>
                  </ContactInfoText>
                </ContactInfoBox>
              </WidgetContainer> */}
          {/* <WidgetContainer>
                <ContactInfoBox>
                  <ContactBoxIcon>
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="phone-alt"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"
                      ></path>
                    </svg>
                  </ContactBoxIcon>
                  <ContactInfoText>
                    <ContactInfoHeder>Our Phone:</ContactInfoHeder>
                    <ContactInfoContent>+47 - 48 63 46 22</ContactInfoContent>
                  </ContactInfoText>
                </ContactInfoBox>
              </WidgetContainer> */}
          {/* </Cell> */}
          {/* <Cell> */}
          <FormContainerBox>
            <FormTitle>Ready to Get Started?</FormTitle>
            <FormSubtitle>
              We'll never share your email with anyone else.
            </FormSubtitle>
            <FormContact></FormContact>
          </FormContainerBox>
          {/* </Cell> */}
          {/* </Grid> */}
        </Container>
      </SectionWrapper>
    </Layout>
  );
};

export default ContactPage;
