import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from '../../theme/constants';
import { device } from '../../theme';

const Wrapper = styled.div``;

const FormSection = styled.form`
  color: ${colors.white};
  font-size: 14px;
  height: inherit;
  line-height: 1.5;
`;

const InputSection = styled.input`
  margin-bottom: 1rem;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  width: 100%;
  outline: none;
  font-size: 14px;
  height: inherit;
  line-height: 1.5;
  border-color: transparent;
  padding: 0.8rem;
  border-radius: 0.4rem;
`;

const TextAreaSection = styled.textarea`
  margin-bottom: 5rem;
  width: 100%;
  font-size: 14px;
  height: 165px;
  line-height: 1.5;
  vertical-align: top;
  padding: 0.8rem;
  border-color: transparent;
  border-radius: 0.4rem;
`;

const FormTitle = styled.p`
  color: ${colors.blue.mainBlue};
  /* margin-bottom: 1rem; */
`;
const Button = styled.button`
  display: flex;
  justify-content: center;
  background-image: none;
  border: 0.2rem solid transparent;
  font-weight: bold;
  font-size: 1.3rem;
  padding: 1.3rem 2rem;
  color: ${colors.blue.mainBlue};
  background-color: ${colors.gray.mainLightGray};
  border-color: transparent;
  border-radius: 0.4rem;
  cursor: pointer;
  :hover {
    /* color: ${colors.yellow.mainLightYellow}; */
    background-color: transparent;
    border: 0.2rem solid #323232;

    /* background-image: linear-gradient(
      90deg,
      rgba(50, 50, 93, 1) 0%,
      rgba(66, 71, 112, 1) 30%,
      rgba(245, 190, 88, 1) 100%
    ); */
  }

  @media ${device.tablet} {
    font-size: 1.5rem;
    line-height: 1.5;
    padding: 1.5rem 2.5rem;
  }
`;
const FormContact = () => {
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const resetForm = (e) => {
    e.preventDefault();
    setSubmitted(false);
  };

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]),
      )
      .join('&');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'softiwarecontact', ...formValues }),
    })
      .then(() => setSubmitted(true))
      .catch((error) => setError(true));
  };

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  return (
    <Wrapper>
      {!submitted ? (
        <FormSection
          method="POST"
          onSubmit={handleSubmit}
          name="softiwarecontact"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          {error && <FormTitle>{error}</FormTitle>}
          <FormTitle>Name*</FormTitle>
          <InputSection
            type="text"
            name="name"
            required
            onChange={handleChange}
            placeholder="Your name"
          />

          <FormTitle>Email*</FormTitle>
          <InputSection
            type="text"
            name="email"
            required
            onChange={handleChange}
            placeholder="Valid email"
          />
          <FormTitle>Phone</FormTitle>
          <InputSection
            type="text"
            name="phone"
            required
            onChange={handleChange}
            placeholder="Phone"
          />
          <FormTitle>Message</FormTitle>
          <TextAreaSection
            rows="10"
            name="message"
            required
            onChange={handleChange}
            placeholder="What do you want to let us know?"
          ></TextAreaSection>
          <input type="hidden" name="form-name" value="contactUs" />
          <Button
            type="submit"
            backgoundcolor={colors.blue.navBlue}
            textColor={colors.white}
          >
            SEND MESSAGE
          </Button>
        </FormSection>
      ) : (
        <>
          <FormTitle>Thank you. We'll contact as soon as possible.</FormTitle>
          <Button onClick={resetForm} bgColor={colors.blue.captionBlue}>
            Resend
          </Button>
        </>
      )}
    </Wrapper>
  );
};

export default FormContact;
